<template>
  <div>
    <div class="hero">
      <v-container>
        <v-row justify="center">
          <v-col cols="12" md="6" class="text-center">
            <div class="heading-content">
              <h1 class="t-title">{{ $t('nolarain.title') }}</h1>
              <p class="t-lead">{{ $t('nolarain.intro') }}</p>
              <auth-button large rounded color="primary" class="my-3">{{ $t('nolarain.signup') }}</auth-button>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <how-it-works-partial />

    <v-container class="my-10">
      <h2 class="t-title text-center">{{ $t('nolarain.getstarted' ) }}</h2>
      <p class="text-center">{{ $t('nolarain.getstarteddescription') }}</p>

      <v-row justify="center">
        <v-col v-for="investigation in investigations" :key="investigation.route" cols="12" sm="4" class="text-center">
          <router-link :to="{ name: 'investigations.detail.description', params: { id: investigation.id } }" class="non-obvious-link">
            <async-image :image="investigation.backgroundImage" :width="500" :height="374" alt="" />
            <h3 class="t-subtitle my-3">{{ investigation.name }}</h3>
          </router-link>
          <p>{{ investigation.cta }}</p>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="my-10">
      <h2 class="t-title text-center">{{ $t('nolarain.stories' ) }}</h2>

      <v-row no-gutters>
        <template v-for="story in stories">
          <v-col :key="story.href" cols="12" sm="6" md="3" class="story-background" :style="{'--story-image': `url('${story.image}')`}">
            <a :href="story.href" class="non-obvious-link">
              <div class="story-block">
                <ul class="story-tags my-2">
                  <li v-for="tag in story.tags" :key="tag" class="story-tag">{{ tag }}</li>
                </ul>
                <time :datetime="story.date">{{ story.date.toISOString() | formatDate('MMMM D, Y') }}</time>
                <p class="t-subtitle">{{ story.description }}</p>
              </div>
            </a>
          </v-col>
        </template>
      </v-row>
    </v-container>
  </div>
</template>

<script lang="ts">
import Vue from '@/vueTyped';
import AsyncImage from '@/components/AsyncImage.vue';
import HowItWorksPartial from '@/components/HowItWorksPartial.vue';
import RouteNames from '@/router/names';
import { Investigation } from '@/types';
import AuthButton from '@/components/AuthButton.vue';

export default Vue.extend({
  name: 'NolaRainLanding',

  components: {
    AsyncImage,
    AuthButton,
    HowItWorksPartial,
  },

  metaInfo: {
    title: 'New Orleans',
  },

  data() {
    return {
      heroImage: 'https://images.prismic.io/iseechange/c386403f-637c-4b7b-935e-60354355fff3_new-orleans-family.jpg?auto=compress,format',

      investigationsToShow: ['flooding', 'heat', 'plants-and-trees'],

      stories: [{
        href: 'https://stories.iseechange.org/summer-storms-reveal-that-new-orleans-has-more-than-just-a-pump-problem/',
        date: new Date('2018-03-02T18:09:44+00:00'),
        image: 'https://stories.iseechange.org/wp-content/uploads/2018/02/wulxvsjn58m1vfac6qho-400x425.jpg',
        description: 'Summer storms reveal that New Orleans has more than just a pump problem',
        tags: ['Community Investigations', 'Deep Dive'],
      }, {
        href: 'https://stories.iseechange.org/new-orleans-floods-gentilly-resilience/',
        date: new Date('2017-08-05T04:24:37+00:00'),
        image: 'https://stories.iseechange.org/wp-content/uploads/2017/08/34980791916_5270ae93ba_o-400x425.jpg',
        description: 'Yes. Your streets are flooding more.',
        tags: ['Community Investigations'],
      }, {
        href: 'https://stories.iseechange.org/green-water-near-the-isle-de-jean-charles-how-safe-and-how-common-are-louisianas-algal-blooms/',
        date: new Date('2016-08-06T11:07:18+00:00'),
        image: 'https://stories.iseechange.org/wp-content/uploads/2016/08/12-400x425.jpg',
        description: 'Green water near the Isle de Jean Charles: how safe and how common are Louisiana’s algal blooms?',
        tags: ['Deep Dive'],
      }, {
        href: 'https://stories.iseechange.org/weird-march-storms-bring-historic-floods/',
        date: new Date('2016-03-31T02:33:08+00:00'),
        image: 'https://stories.iseechange.org/wp-content/uploads/2016/04/fvolv02qp7gz93vefw94-400x425.jpeg',
        description: '“Weird” March storms bring historic floods',
        tags: ['Deep Dive'],
      }],
    }
  },

  computed: {
    investigations(): Investigation[] {
      let allInvestigations = this.$store.state.investigations.items;
      if (allInvestigations) {
        return this.investigationsToShow.map(slugToShow => {
          return allInvestigations.find(investigation => investigation.slug === slugToShow);
        }).filter(Boolean) as Investigation[];
      } else {
        return [];
      }
    }
  },

  created() {
    this.$store.dispatch('fetchInvestigations');
  },
});
</script>

<style lang="postcss" scoped>
  .hero {
    background:
      linear-gradient(#0006 50%, #0000),
      url("https://images.prismic.io/iseechange/c386403f-637c-4b7b-935e-60354355fff3_new-orleans-family.jpg?auto=compress,format") 50% 30%;
    background-size: cover;
    color: white;
    min-height: 35vw;
    padding: 5rem 0;
  }

  .non-obvious-link {
    text-decoration: none;
  }

  .story-background {
    background:
      linear-gradient(#0006 50%, #0000),
      var(--story-image, #888) 50% 50%;
    background-size: cover;
  }

  .story-block {
    color: white;
    display: block;
    min-height: 20vw;
    padding: 1rem;
  }

  .story-tags {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .story-tag {
    background: var(--color-danger, black);
    display: inline-block;
    margin-right: 1ch;
    padding: 0 1ch;
  }
</style>
