<template>
    <div>
        <header class="landing-page-header" :data-narrow="$vuetify.breakpoint.smAndDown">
            <primary-logo class="iseechange-logo" :height="$vuetify.breakpoint.mdAndUp ? 50 : 33" />
            <template v-if="content.partnerLogo || content.partnerName">
                <span class="logo-joiner">+</span>
                <img
                    v-if="content.partnerLogo"
                    :srcset="`${content.partnerLogo} ${$vuetify.breakpoint.mdAndUp ? '2x' : '3x'}`"
                    :alt="content.partnerName"
                    class="partner-logo"
                    :style="`--logo-baseline: ${content.partnerLogoBaseline}; --logo-scale: ${$vuetify.breakpoint.mdAndUp ? '0.5' : '0.33'}`"
                >
                <span v-else class="partner-name">{{ content.partnerName }}</span>
            </template>
        </header>

        <section class="hero">
            <img v-if="content.heroImage2x || content.heroImage" :srcset="`${content.heroImage2x || content.heroImage} 1500w, ${content.heroImage}`" alt="" class="hero-background">
            <div class="hero-underlay" />

            <div class="content-container hero-container">
                <v-row justify="center" justify-lg="space-between">
                    <v-col cols="11" lg="7" align-self="center" class="mb-16">
                        <div class="hero-content t-lead">
                            <h1 class="t-title my-8">
                                <markdown-output :value="content.headline" inline trusted />
                            </h1>
                            <markdown-output :value="content.intro" />
                            <div class="text-center mt-8">
                                <small>
                                    <strong>{{ content.getTheApp }}</strong>
                                </small>

                                <app-download-links outlined @click-link="handleAppDownloadLinkClick" />
                            </div>
                        </div>
                    </v-col>

                    <v-col cols="11" lg="4">
                        <article ref="registrationForm" class="registration-form-wrapper" :data-narrow="$vuetify.breakpoint.mdAndDown">
                            <header class="registration-form-header">
                                <h2 class="registration-form-heading">{{ content.registrationFormHeading }}</h2>
                            </header>

                            <div class="registration-form-content">
                                <registration-form embedded @success="handleRegistrationSuccess" />
                            </div>
                        </article>
                    </v-col>
                </v-row>
            </div>
        </section>

        <section v-if="$route.params.partner === 'girl-scouts'" class="girl-scouts">
             <div class="content-container py-14">
                <h2 class="section-heading text-center mb-6">How it Works</h2>

                <v-row justify="center" :data-smaller-icons="$vuetify.breakpoint.mdAndDown">
                    <v-col cols="12" sm="4" md="3" class="girl-scouts-block">
                        <p class="t-lead mb-8">Use the same email you use on SciStarter</p>
                        <img src="/img/campaigns/girl-scouts/email.png" alt="Email" width="162" height="121" class="girl-scouts-icon" style="margin: calc((160px - 121px) / 2) 0;">
                    </v-col>

                    <v-col cols="12" sm="4" md="3" offset-md="1" class="girl-scouts-block">
                        <p class="t-lead mb-8">Good posts share stories</p>
                        <img src="/img/campaigns/girl-scouts/story.png" alt="Documentation" width="139" height="160" class="girl-scouts-icon">
                    </v-col>

                    <v-col cols="12" sm="4" md="3" offset-md="1" class="girl-scouts-block">
                        <p class="t-lead mb-8">Ask family what’s changed over the years</p>
                        <img src="/img/campaigns/girl-scouts/talk.png" alt="Talking with family" width="160" height="160" class="girl-scouts-icon">
                    </v-col>
                </v-row>

                <v-row justify="center">
                    <v-col md="6">
                        <p>You can use ISeeChange as part of your <a href="https://scistarter.com/girlscouts/info">SciStarter Think Like a Citizen Scientist Journey</a> — just be sure to create your ISeeChange account with the same email you use on SciStarter. We are excited to see what you have to share about your climate and weather experience.</p>
                    </v-col>
                </v-row>
            </div>
        </section>

        <section v-if="content.secondaryHeadline || content.secondaryContent" class="whitby-section">
            <v-container class="py-12">
                <v-row justify="center">
                    <v-col md="8">
                        <h2 v-if="content.secondaryHeadline" class="section-heading text-center mb-8">{{ content.secondaryHeadline }}</h2>
                        <markdown-output v-if="content.secondaryContent" :value="content.secondaryContent" />
                    </v-col>
                </v-row>
            </v-container>
        </section>

        <section v-if="$route.params.partner !== 'girl-scouts'" class="how-it-works py-11">
            <div class="content-container">
                <v-row justify="center">
                    <v-col cols="10">
                        <h2 class="section-heading text-center">{{ content.howItWorksHeadline }}</h2>

                        <v-row align="center" justify="center">
                            <v-col tag="ul" cols="12" md="6" class="unstyled-list">
                                <v-row tag="li" align="center" class="how-it-works-entry">
                                    <v-col cols="4" class="how-it-works-icon-wrapper">
                                        <img src="/img/campaigns/partners-shared/voice.png" alt="Abstract illustration of a globe flanked by weather symbols" class="how-it-works-icon">
                                    </v-col>

                                    <v-col class="how-it-works-explanation">
                                        <markdown-output :value="content.howItWorksVoice" trusted />
                                    </v-col>
                                </v-row>

                                <v-row tag="li" align="center" class="how-it-works-entry">
                                    <v-col cols="4" class="how-it-works-icon-wrapper">
                                        <img src="/img/campaigns/partners-shared/community.png" alt="Abstract illustration of a neighborhood" class="how-it-works-icon">
                                    </v-col>

                                    <v-col class="how-it-works-explanation">
                                        <markdown-output :value="content.howItWorksCommunity" trusted />
                                    </v-col>
                                </v-row>

                                <v-row tag="li" align="center" class="how-it-works-entry">
                                    <v-col cols="4" class="how-it-works-icon-wrapper">
                                        <img src="/img/campaigns/partners-shared/any-time.png" alt="Abstract illustration of a human hand manipulating a phone" class="how-it-works-icon">
                                    </v-col>

                                    <v-col class="how-it-works-explanation">
                                        <markdown-output :value="content.howItWorksAnyTime" trusted />
                                    </v-col>
                                </v-row>
                            </v-col>

                            <v-col cols="12" md="6" class="screenshots-layout text-center">
                                <img src="/img/campaigns/partners-shared/post-form.jpg" alt="A screenshot of the Sighting creation form" width="303" class="screenshot">
                                <img src="/img/campaigns/partners-shared/example-post.jpg" alt="A screenshot of an example post" width="303" class="screenshot">
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </div>
        </section>

        <div v-if="Object.assign([], content.projects).length !== 0" class="content-container my-15">
            <h2 class="section-heading text-center mb-8">{{ content.projectsHeadline }}</h2>

            <v-row tag="ul" justify="center" class="unstyled-list">
                <v-col v-for="project in Object.assign([], content.projects)" :key="`${project.title}-${project.url}`" tag="li" cols="10" sm="4">
                    <a :href="project.url" class="block-link">
                        <img :src="project.image" :alt="project.imageDescription" class="investigation-image">
                        <h3 class="t-subtitle text-center my-3">{{ project.title }}</h3>
                    </a>

                    <p>{{ project.description }}</p>
                </v-col>
            </v-row>
        </div>

        <div v-if="investigations.length !== 0" class="content-container my-15">
            <h2 class="section-heading text-center my-8">{{ content.topicsHeadline }}</h2>

            <v-row tag="ul" justify="center" class="unstyled-list">
                <v-col v-for="investigation in investigations" :key="investigation.route" tag="li" cols="10" sm="4">
                    <router-link :to="{ name: 'investigations.detail.description', params: { id: investigation.id } }" class="block-link">
                        <img :src="investigation.backgroundImage | formatImage(640)" alt="" class="investigation-image">
                        <h3 class="t-subtitle text-center my-3">
                            <template v-if="$root.$te(`overrides.investigations.${investigation.slug}.name`)">
                                {{ $t(`overrides.investigations.${investigation.slug}.name`) }}
                            </template>
                            <template v-else>
                                {{ investigation.name }}
                            </template>
                        </h3>
                    </router-link>

                    <p>{{ investigation.cta }}</p>
                </v-col>
            </v-row>
        </div>

        <div v-if="fetchingPosts || posts.length !== 0" class="content-container my-15">
            <h2 class="section-heading text-center my-8">{{ content.postsHeadline }}</h2>

            <div v-if="fetchingPosts" style="height: 5em; position: relative;">
                <loading-indicator />
            </div>

            <v-row v-else tag="ul" justify="center" class="unstyled-list">
                <template v-for="post in posts">
                    <v-col
                        :key="post.id"
                        tag="li"
                        cols="10"
                        sm="6"
                        lg=""
                        style="display: flex;"
                    >
                        <div class="post-container" :style="{'--post-image': `url('${$options.filters.formatImage(post.photoObjs[0], 640)}')`}">
                            <router-link :to="{ name: 'posts.detail', params: { id: post.id } }" class="block-link">
                                <div class="post-content pa-6">
                                    <time :datetime="post.observedAt">{{ post.observedAt | formatDate('MMMM D, Y') }}</time>
                                    <p class="t-lead">
                                        <template v-if="$root.$te(`overrides.posts.${post.id}.textBody`)">
                                            {{ $t(`overrides.posts.${post.id}.textBody`) }}
                                        </template>
                                        <template v-else>
                                            {{ post.textBody }}
                                        </template>
                                    </p>
                                </div>
                            </router-link>
                        </div>
                    </v-col>
                </template>
            </v-row>
        </div>

        <div v-if="Object.assign([], content.partners).length !== 0" class="content-container my-15 text-center">
            <h2 class="section-heading my-8">{{ content.partnersHeadline }}</h2>

            <v-row tag="ul" justify="center" align="center" class="unstyled-list">
                <v-col v-for="partner in Object.assign([], content.partners)" :key="`${partner.logo}-${partner.name}`" tag="li">
                    <component :is="partner.url ? 'a' : 'span'" :href="partner.url" target="_blank">
                        <img :src="partner.logo" :alt="partner.name" :width="partner.logoWidth">
                    </component>
                </v-col>
            </v-row>
        </div>

        <section v-if="Object.assign([], content.media).length !== 0" class="media py-15 text-center">
            <div class="content-container">
                <h2 class="section-heading mb-8">{{ content.mediaHeadline }}</h2>

                <ul class="unstyled-list">
                    <li v-for="medium in Object.assign([], content.media)" :key="`${medium.logo}-${medium.name}`">
                        <img :src="medium.logo" :alt="medium.name">
                    </li>
                </ul>
            </div>
        </section>

        <section class="sharing py-15 text-center">
            <div class="content-container">
                <h2 class="section-heading mb-8">{{ content.shareHeading }}</h2>

                <share-network tag="button" network="twitter" v-bind="shareProps">
                    <v-icon large class="mr-8" style="color: inherit;">$twitter</v-icon>
                </share-network>

                <share-network tag="button" network="facebook" v-bind="shareProps">
                    <v-icon large class="mr-8" style="color: inherit;">$facebook</v-icon>
                </share-network>

                <share-network tag="button" network="linkedin" v-bind="shareProps">
                    <v-icon large class="mr-8" style="color: inherit;">$linkedin</v-icon>
                </share-network>

                <a :href="`mailto:?subject=${shareProps.title}&body=${shareProps.url}`" style="color: inherit; text-decoration: none;">
                    <v-icon large style="color: inherit;">email</v-icon>
                </a>
            </div>
        </section>

        <base-button
            v-if="!$store.state.account.currentUser"
            color="primary"
            class="sign-up-follower"
            :data-offscreen="formIsVisible"
            large
            @click="scrollToRegistrationForm"
        >Sign up</base-button>
    </div>
</template>

<script lang="ts">
import Vue from '@/vueTyped';
import { Investigation, Post } from '@/types';
import orderBy from 'lodash/orderBy';
import AppDownloadLinks from '@/components/AppDownloadLinks.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import MarkdownOutput from '@/components/MarkdownOutput.vue';
import PrimaryLogo from '@/components/logo/Primary.vue';
import RegistrationForm from '@/components/RegistrationForm.vue';
import { MetaInfo } from 'vue-meta';
import RouteNames from '@/router/names';
import { trackInFacebook } from '@/tracking';
import { CLIENT_ORIGIN } from '@/config';

type Content = {
    facebookPixelId?: string;
    pageTitle: string;
    heroImage: string;
    heroImage2x?: string;
    headline: string;
    intro: string;
    partnerSlug: string;
    investigations: Investigation['slug'][];
    posts: Post['id'][];
    howItWorksHeadline: string,
    topicsHeadline: string,
    postsHeadline: string,
};

export default Vue.extend({
    components: {
        AppDownloadLinks,
        LoadingIndicator,
        MarkdownOutput,
        PrimaryLogo,
        RegistrationForm,
    },

    metaInfo(): MetaInfo {
        return {
            title: this.content.pageTitle,
        };
    },

    data() {
        return {
            scrollObserver: null as IntersectionObserver | null,
            formIsVisible: true,
            fetchingPosts: false,
            posts: [] as Post[],
        };
    },

    computed: {
        // We'll automatically correct any case/hyphenation differences.
        caseCorrectedPartnerKey(): string {
            const simplify = (fragment: string) => fragment.toUpperCase().replace(/\W/g, '');
            const givenPartner = simplify(this.$route.params.partner);
            const knownPartnerKeys = Object.keys(this.$t('partnerLandingPages'));
            const matchingPartnerKey = knownPartnerKeys.find(key => simplify(key) === givenPartner);
            return matchingPartnerKey ?? this.$route.params.partner;
        },

        content(): Content {
            return {
                ...this.$t(`partnerLandingPages._defaults`, 'en') as Object,
                ...this.$t(`partnerLandingPages._defaults`) as Object,
                ...this.$t(`partnerLandingPages.${this.caseCorrectedPartnerKey}`, 'en') as Object,
                ...this.$t(`partnerLandingPages.${this.caseCorrectedPartnerKey}`) as Object,
            } as Content;
        },

        investigations(): Investigation[] {
            // Investigations are fetched on sign-in.
            let allInvestigations = this.$store.state.investigations.items;

            if (allInvestigations) {
                return this.content.investigations.map(slug => {
                    return allInvestigations.find(investigation => investigation.slug === slug);
                }).filter(Boolean) as Investigation[];
            } else {
                return [];
            }
        },

        shareProps(): object {
            return {
                url: new URL(CLIENT_ORIGIN).href,
                title: 'ISeeChange | Community Climate and Weather Journal',
                hashtags: 'iseechange,climatechange',
            };
        },
    },

    watch: {
        '$route.params.partner': {
            immediate: true,
            handler() {
                this.trackInFacebook('ViewContent', { path: this.$route.fullPath });
            },
        },

        caseCorrectedPartnerKey: {
            immediate: true,
            handler(caseCorrectedPartnerKey) {
                if (caseCorrectedPartnerKey && caseCorrectedPartnerKey !== this.$route.params.partner) {
                    this.$router.replace({ params: {partner: caseCorrectedPartnerKey } });
                }
            },
        },
    },

    async mounted() {
        const navHeight = getComputedStyle(document.documentElement).getPropertyValue('--webnavigation--height');
        this.scrollObserver = new IntersectionObserver(this.handleIntersectionChange, { rootMargin: `-${navHeight.trim()} 0px` });
        this.scrollObserver.observe(this.$refs.registrationForm as Element);
        this.fetchPosts();
    },

    beforeDestroy() {
        this.scrollObserver?.disconnect();
    },

    methods: {
        trackInFacebook(event: string, properties: Record<string, unknown> = {}) {
            if (this.content.facebookPixelId) {
                trackInFacebook(this.content.facebookPixelId, event, {
                    locale: this.$i18n.locale,
                    ref: this.$route.query.ref,
                    ...properties
                });
            }
        },

        handleIntersectionChange(entries: IntersectionObserverEntry[]) {
            const { intersectionRatio } = entries[0];
            this.formIsVisible = intersectionRatio !== 0;
        },

        async fetchPosts() {
            this.fetchingPosts = true;

            const posts = await Promise.all(this.content.posts.map(async id => {
                const { data } = await this.$store.dispatch('fetchPostById', { id });
                return data;
            }));

            this.posts = posts.filter(Boolean);

            for (const post of this.posts) {
                post.photoObjs = orderBy(post.photoObjs, 'created_at') as Post['photoObjs'];
            }

            this.fetchingPosts = false;
        },

        handleAppDownloadLinkClick(platform: string) {
            if (this.content.facebookPixelId) {
                this.trackInFacebook('Click app download', { platform });
            }
        },

        scrollToRegistrationForm() {
            (this.$refs.registrationForm as Element).scrollIntoView({ behavior: 'smooth' });
        },

        handleRegistrationSuccess() {
            this.trackInFacebook('CompleteRegistration');

            this.$router.push({
                name: RouteNames.REGISTER,
                query: {
                    redirect: this.$router.resolve({
                        name: RouteNames.REGISTER,
                        query: {
                            ...this.$route.query,
                            show: 'apps',
                        }
                    }).href,
                },
            });
        },

        getCurrentLocation(): string {
            return location.href;
        }
    },
});
</script>

<style lang="postcss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

.content-container {
    margin-inline: auto;
    max-width: min(1344px, calc(1344 / 1440 * 100vw));
}

.section-heading {
    font-size: calc(var(--type-title) * 0.85);
}

.block-link {
    text-decoration: none;
}

.unstyled-list {
    list-style: none;
    padding: 0;
}

.sign-up-follower {
    margin: var(--spacing-4);
    position: fixed;
    right: 0;
    top: 0;
    transform: translateY(0);
    transition: transform 1s;
    z-index: 1;
}

.sign-up-follower[data-offscreen] {
    transform: translateY(calc(var(--spacing-6) * -1)) translateY(-100%);
}

.landing-page-header {
    /* align-items: center; */
    background: var(--color-secondary);
    box-shadow: 0 0 10px black;
    color: white;
    /* display: flex; */
    /* justify-content: center; */
    padding: var(--spacing-4);
    position: relative;
    text-align: center;
    z-index: 1;
}

.iseechange-logo {
    vertical-align: -7px; /* Baseline from image. */
}

.logo-joiner {
    display: inline-block;
    font-size: 40px;
    margin-inline: 1ch;
}

[data-narrow] .logo-joiner {
    font-size: 30px;
}

.partner-logo {
    vertical-align: calc(var(--logo-baseline) * var(--logo-scale) * -1px);
}

.partner-name {
    border-radius: 5px;
    display: inline-block;
    font-family: var(--type-headline);
    font-size: 40px;
    font-weight: bold;
}

[data-narrow] .partner-name {
    font-size: 30px;
}

.hero {
    background: var(--color-secondary);
    color: white;
    padding: 5vh 0;
    position: relative;
}

.hero-cta-button {
    text-shadow: none;
}

.hero-background {
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
}

.hero-underlay {
    background: linear-gradient(#0006 50%, #0000);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.hero-container {
    position: relative;
}

.hero-content {
    text-shadow: 0 2px 5px black;
}

.registration-form-wrapper {
    filter: drop-shadow(0 10px 10px #0003);
    margin-inline: auto;
    max-width: 60ch;
    scroll-margin-top: calc(var(--webnavigation--height) + var(--spacing-4));
}

.registration-form-wrapper:not([data-narrow]) {
    margin-bottom: calc(-5vh - 3em);
}


.registration-form-header {
    background: linear-gradient(135deg, #A2CD3D, #44ACAD);
    border-radius: 20px 20px 0 0;
    color: white;
    padding: var(--spacing-4) var(--spacing-8);
    text-align: center;
}

.registration-form-heading {
    font-family: 'Bebas Neue', sans-serif;
    font-size: 1.7rem;
    letter-spacing: 0.05ch;
    margin: 0;
    text-transform: uppercase;
}

.registration-form-content {
    background: white;
    border-radius: 0 0 20px 20px;
    color: black;
    padding: var(--spacing-4) var(--spacing-8);
}

.girl-scouts {
    background: var(--color-light);
}

.girl-scouts-block {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
}

[data-smaller-icons] .girl-scouts-icon {
    height: auto;
    width: 80px;
}

.whitby-section {
    background: var(--color-light);
    font-size: calc(var(--type-subtitle) / (32 / 26));
}

.how-it-works {
    background: linear-gradient(#0000 75%, #0001);
}

.how-it-works-icon-wrapper {
    position: relative;
}

.how-it-works-entry:not(:last-child) > .how-it-works-icon-wrapper:after {
    background: var(--color-primary);
    content: "";
    height: 120%; /* This isn't perfect, but it's probably flexible enough. */
    left: 50%;
    margin-left: -0.75rem;
    position: absolute;
    top: 50%;
    width: 1.5rem;
}

.how-it-works-icon {
    /* margin-block: var(--spacing-8); */
    position: relative;
    z-index: 1;
}

.how-it-works-explanation {
    font-size: calc(var(--type-subtitle) / (24 / 16))1;
    margin-inline-end: 1em;
}

.how-it-works-explanation :deep(h3) {
    color: var(--color-danger);
    font-size: var(--type-subtitle);
}

.screenshots-layout {
    align-items: center;
    display: flex;
    justify-content: center;
}

.screenshots-layout > :first-child {
    margin: 4rem -4rem 0 0;
    position: relative;
    z-index: 1;
}

.screenshot {
    mask-image: url(/img/campaigns/partners-shared/phone-mask.png);
    mask-size: cover;
    min-width: 0;
}

.investigation-image {
    aspect-ratio: 20 / 13;
    object-fit: cover;
    width: 100%;
}

.post-container {
    background: linear-gradient(#0008 50%, #0000), var(--post-image, #888) 50% 50% / cover var(--color-secondary);
    width: 100%;
}

.post-content {
    aspect-ratio: 1;
    color: white;
}

.media {
    background: #8882;
}

.sharing {
    background: linear-gradient(135deg, #A2CD3D55, #44ACAD55);
}

.icon-link {
    color: inherit;
    text-decoration: inherit;
}
</style>
